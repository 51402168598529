$aos-distance: 50px;

$enable-responsive-font-sizes: false;
$rfs-rem-value: 16;

$primary: map-get($colors, 'orange');//#2e79bd;

//$grid-columns:      12;
$grid-gutter-width: 20px;

//$grid-breakpoints: (
//        xxs: 0,
//  // Extra small screen / phone
//        xs: 480px,
//  // Small screen / phone
//        sm: 576px,
//  // Medium screen / tablet
//        md: 768px,
//  // Large screen / desktop
//        lg: 992px,
//  // Extra large screen / wide desktop
//        xl: 1200px,
//  // XExtra large screen / wide desktop
//        xxl: 1600px,//1480px,
//        rt: 2560px,
//        rt15: 2880px,
//        uhd: 3840px,
//        4k: 4096px,
//        8k: 8192px
//);

$grid-breakpoints: (
        xxs: 0,
        xs: 480px,
        sm: 680px,
        md: 900px,
        lg: 1180px,
        xl: 1400px,
        xxl: 1600px,
        rt: 2200px,
        rt15: 2880px,
        uhd: 3840px,
        4k: 4200px,
        8k: 6000px
);

//$container-max-widths: (
//        sm: 540px,
//        md: 720px,
//        lg: 960px,
//        xl: 1140px,
//        xxl: 1480px,//1140px,
//        rt: 1480px,
//        rt15: 1920px,
//        uhd: 2820px,
//        4k: 3840px,
//        8k: 4036px
//);

$container-max-widths: (
        md: 840px,
        lg: 1030px,
        xl: 1100px,
        xxl: 1250px,
        rt: 1480px,
        rt15: 1920px,
        uhd: 2820px,
        4k: 3240px,
        8k: 4036px
);






/*
    Created on : 2016-06-29, 13:30:48
    Author     : Ewitryna
*/

//== Colors
//
//## Gray and brand colors for use across Bootstrap.

//$gray-base:              #000;
//$gray-darker:            lighten($gray-base, 13.5%); // #222
//$gray-dark:              lighten($gray-base, 20%);   // #333
//$gray:                   lighten($gray-base, 33.5%); // #555
//$gray-light:             lighten($gray-base, 46.7%); // #777
//$gray-lighter:           lighten($gray-base, 93.5%); // #eee

$brand-primary:         $main_color; // #337ab7
//brand-success:         #5cb85c;
//$brand-info:            #5bc0de;
//$brand-warning:         #f0ad4e;
//$brand-danger:          #d9534f;

//== Scaffolding
//
//## Settings for some of the most global styles.

//** Background color for `<body>`.
//$body-bg:               #fff;
//** Global text color on `<body>`.
$text-color:            $main_text_color;

//** Global textual link color.
$link-color:            $main_color;
//** Link hover color set via `darken()` function.
$link-hover-color:      $link-color;
//** Link hover decoration.
//$link-hover-decoration: underline;



// Navbar collapse
//** Point at which the navbar becomes uncollapsed.
//$grid-float-breakpoint:     $screen-sm-min;
//** Point at which the navbar begins collapsing.
//$grid-float-breakpoint-max: ($grid-float-breakpoint - 1);

//== Navbar
//
//##

// Basics of a navbar
//$navbar-height:                    0px;
//$navbar-margin-bottom:             $line-height-computed;
//$navbar-border-radius:             0px;
//$navbar-padding-horizontal:        floor(($grid-gutter-width / 2));
//$navbar-padding-vertical:          (($navbar-height - $line-height-computed) / 2);
//$navbar-collapse-max-height:       340px;

//$navbar-default-color:             #777;
//$navbar-default-bg:                #f8f8f8;
//$navbar-default-border:            darken($navbar-default-bg, 6.5%);

// Navbar links
//$navbar-default-link-color:                #777;
//$navbar-default-link-hover-color:          #333;
//$navbar-default-link-hover-bg:             transparent;
//$navbar-default-link-active-color:         #555;
//$navbar-default-link-active-bg:            darken($navbar-default-bg, 6.5%);
//$navbar-default-link-disabled-color:       #ccc;
//$navbar-default-link-disabled-bg:          transparent;

// Navbar brand label
//$navbar-default-brand-color:               $navbar-default-link-color;
//$navbar-default-brand-hover-color:         darken($navbar-default-brand-color, 10%);
//$navbar-default-brand-hover-bg:            transparent;

// Navbar toggle
//$navbar-default-toggle-hover-bg:           #ddd;
//$navbar-default-toggle-icon-bar-bg:        #888;
//$navbar-default-toggle-border-color:       #ddd;


//=== Inverted navbar
// Reset inverted navbar basics
//$navbar-inverse-color:                      lighten($gray-light, 15%);
//$navbar-inverse-bg:                         #222;
//$navbar-inverse-border:                     darken($navbar-inverse-bg, 10%);

// Inverted navbar links
//$navbar-inverse-link-color:                 lighten($gray-light, 15%);
//$navbar-inverse-link-hover-color:           #fff;
//$navbar-inverse-link-hover-bg:              transparent;
//$navbar-inverse-link-active-color:          $navbar-inverse-link-hover-color;
//$navbar-inverse-link-active-bg:             darken($navbar-inverse-bg, 10%);
//$navbar-inverse-link-disabled-color:        #444;
//$navbar-inverse-link-disabled-bg:           transparent;

// Inverted navbar brand label
//$navbar-inverse-brand-color:                $navbar-inverse-link-color;
//$navbar-inverse-brand-hover-color:          #fff;
//$navbar-inverse-brand-hover-bg:             transparent;

// Inverted navbar toggle
//$navbar-inverse-toggle-hover-bg:            #333;
//$navbar-inverse-toggle-icon-bar-bg:         #fff;
//$navbar-inverse-toggle-border-color:        #333;


//== Navs
//
//##

//=== Shared nav styles
//$nav-link-padding:                          10px 15px;
//$nav-link-hover-bg:                         $gray-lighter;

//$nav-disabled-link-color:                   $gray-light;
//$nav-disabled-link-hover-color:             $gray-light;

//== Tabs
//$nav-tabs-border-color:                     #ddd;

//$nav-tabs-link-hover-border-color:          $gray-lighter;

//$nav-tabs-active-link-hover-bg:             $body-bg;
//$nav-tabs-active-link-hover-color:          $gray;
//$nav-tabs-active-link-hover-border-color:   #ddd;

//$nav-tabs-justified-link-border-color:            #ddd;
//$nav-tabs-justified-active-link-border-color:     $body-bg;

//== Pills
//$nav-pills-border-radius:                   $border-radius-base;
//$nav-pills-active-link-hover-bg:            $component-active-bg;
//$nav-pills-active-link-hover-color:         $component-active-color;

//== Pagination
//
//##

//$pagination-color:                     $link-color;
//$pagination-bg:                        transparent;
//$pagination-border:                    transparent;

//$pagination-hover-color:               #fff;
//$pagination-hover-bg:                  $brand-primary;
//$pagination-hover-border:              transparent;

//$pagination-active-color:              #fff;
//$pagination-active-bg:                 $brand-primary;
//$pagination-active-border:             $brand-primary;

//$pagination-disabled-color:            $gray-light;
//$pagination-disabled-bg:               #fff;
//$pagination-disabled-border:           transparent;

$table-head-bg: lighten(map-get($colors,'light-grey'),10%);

$tooltip-opacity: 1;
$tooltip-arrow-color: $main_color;
$tooltip-padding-y: 8px;
$tooltip-padding-x: 10px;
$tooltip-bg: $main_color;
$tooltip-border-radius: 0;


// Tables
//
// Customizes the `.table` component with basic values, each used across all table variations.

//$table-cell-padding:          .75rem !default;
//$table-cell-padding-sm:       .3rem !default;

$table-color:                 $main_text_color;
$table-bg:                    $main_bg;
$table-accent-bg:             #efefef;
$table-hover-color:           $table-color;
$table-hover-bg:              #dedede;
$table-active-bg:             $table-hover-bg;

//$table-border-width:          $border-width !default;
$table-border-color:          #d4d4d4;

$table-head-bg:               null;
$table-head-color:            $main_text_color;
$table-th-font-weight:        null;

//$table-dark-color:            $white !default;
//$table-dark-bg:               $gray-800 !default;
//$table-dark-accent-bg:        rgba($white, .05) !default;
//$table-dark-hover-color:      $table-dark-color !default;
//$table-dark-hover-bg:         rgba($white, .075) !default;
//$table-dark-border-color:     lighten($table-dark-bg, 7.5%) !default;

//$table-striped-order:         odd !default;

//$table-caption-color:         $text-muted !default;

//$table-bg-level:              -9 !default;
//$table-border-level:          -6 !default;

